@import url("https://fonts.googleapis.com/css2?family=Ropa+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:400");

@mixin aspect-ratio($width, $height) {
    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    > .img2 {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}



// Styling

.App{
    background-size: 100% 100%;
    background-blend-mode: screen;
    // position: fixed;
    top: 0;
    left: 0;
    height: 1300px;
    z-index: -2;
    background-image: url(../assets/backgroundRoxo.png);
    background-repeat: no-repeat;
}

section {
    max-width: 100%;
    height: 850px;
    max-height: 100%;
    // background: black;
    // padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    // overflow-x: hidden;
    overflow-y: visible;
    // background-color: #fff;
    
    
}


// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// .App{
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
// }

.container {

    // margin: 0 auto;
    // padding: 0 20px;
    // position: absolute;
    // top: 0;
    
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // background-color: blue;
    width: 90%;
    height: 90%;
}

.container h1 {
    font-family: "Poppins", sans-serif;
    font-size: 45px;
    background-color: #000;
}

.carousel {
    // display: block;
    text-align: left;
    // position: relative;
    // margin-bottom: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    


    > input {
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        width: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;

        &:nth-of-type(6):checked ~ .carousel__slides .carousel__slide:first-of-type {
            margin-left: -500%;
        }
        &:nth-of-type(5):checked ~ .carousel__slides .carousel__slide:first-of-type {
            margin-left: -400%;
        }
        &:nth-of-type(4):checked ~ .carousel__slides .carousel__slide:first-of-type {
            margin-left: -300%;
        }
        &:nth-of-type(3):checked ~ .carousel__slides .carousel__slide:first-of-type {
            margin-left: -200%;
        }
        &:nth-of-type(2):checked ~ .carousel__slides .carousel__slide:first-of-type {
            margin-left: -100%;
        }
        &:nth-of-type(1):checked ~ .carousel__slides .carousel__slide:first-of-type {
            margin-left: 0%;
        }

        &:nth-of-type(1):checked ~ .carousel__thumbnails li:nth-of-type(1) {
            box-shadow: 0px 0px 0px 5px #71fe95;
        }
        &:nth-of-type(2):checked ~ .carousel__thumbnails li:nth-of-type(2) {
            box-shadow: 0px 0px 0px 5px #71fe95;
        }
        &:nth-of-type(3):checked ~ .carousel__thumbnails li:nth-of-type(3) {
            box-shadow: 0px 0px 0px 5px #71fe95;
        }
        &:nth-of-type(4):checked ~ .carousel__thumbnails li:nth-of-type(4) {
            box-shadow: 0px 0px 0px 5px #71fe95;
        }
        &:nth-of-type(5):checked ~ .carousel__thumbnails li:nth-of-type(5) {
            box-shadow: 0px 0px 0px 5px #71fe95;
        }
        &:nth-of-type(6):checked ~ .carousel__thumbnails li:nth-of-type(6) {
            box-shadow: 0px 0px 0px 5px #6000db;
        }
    }
}

.carousel__slides {
    position: relative;
    z-index: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    display: flex;
}

.carousel__slide {
    position: relative;
    display: block;
    flex: 1 0 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: all 300ms ease-out;

    box-sizing: border-box;
    white-space: normal;

    figure {
        display: flex;
        margin: 0;
    }

    div {
        @include aspect-ratio(3, 2);
        width: 100%;
    } 

    .img2 {
        display: block;
        flex: 1 1 auto;
        object-fit: cover;
    }

    figcaption {
        align-self: flex-end;
        padding: 20px 20px 0 20px;
        flex: 0 0 auto;
        width: 25%;
        min-width: 150px;
        font-family: "Poppins", sans-serif;
        color: white;
    }

    .credit {
        margin-top: 1rem;
        color: whitesmoke;
        display: block;
    }

    &.scrollable {
        overflow-y: scroll;
    }
}

.carousel__thumbnails {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    margin: 0 -10px;
    // background-color: #fff;
    .carousel__slides + & {
        margin-top: 20px;
    }

    li {
        flex: 1 1 auto;
        max-width: calc((100% / 6) - 20px);
        margin: 0 10px;
        transition: all 300ms ease-in-out;
    }

    label {
        display: block;
        @include aspect-ratio(1, 1);

        &:hover,
        &:focus {
            cursor: pointer;

            img {
                box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.25);
                transition: all 300ms ease-in-out;
            }
        }
    }

    .img2 {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
