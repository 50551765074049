/* Config */
$color-bg: rgb(68, 27, 66);
$color-particle: #71fe95;
$spacing: 2560px;
$time-1: 60s;
$time-2: 120s;
$time-3: 180s;
$time-4: 200s;

/* mixins */
@function particles($max) {
    $val: 0px 0px $color-particle;
    @for $i from 1 through $max {
        $val: #{$val}, random($spacing) + px random($spacing) + px $color-particle;
    }
    @return $val;
}

@mixin particles($max) {
    box-shadow: particles($max);
    border-radius: 50%;
}

/* Styles */
// html, body {
//   height: 100%;


.img{
    // position: absolute;
    // background-color: #fff;
    max-width: 30%;
    height: auto;
    // margin-top: 2%;
    margin-left: 5%;
}

.imgAnterior{
    width: 100%;
    // background-color: blue;
    
    

}

// body {
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     overflow: hidden;
// }

.page-bg,
.animation-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
}

.page-bg {
    //   background: rgb(87, 16, 94);
    // background-image: url(../assets/backgroundRoxo.png);
    background-repeat: no-repeat;
// background-color: #fff;
    background-blend-mode: screen;
    background-size: cover;
    //   filter: grayscale(100%);
    z-index: 0;
}

.particle,
.particle:after {
    background: transparent;
}

.particle:after {
    position: absolute;
    content: "";
    top: $spacing;
}

.particle-1 {
    animation: animParticle $time-1 linear infinite;
    @include particles(1000);
    height: 3px;
    width: 3px;
}

.particle-1:after {
    @include particles(900);
    height: 0.5px;
    width: 0.5px;
}

.particle-2 {
    animation: animParticle $time-2 linear infinite;
    @include particles(800);
    height: 2px;
    width: 2px;
}

.particle-2:after {
    @include particles(500);
    height: 3px;
    width: 3px;
}

.particle-3:after {
    @include particles(1100);
    height: 3px;
    width: 3px;
    border-radius: 50%;
}

.particle-4 {
    animation: animParticle $time-4 linear infinite;
    @include particles(1200);
    height: 1px;
    width: 1px;
}

.particle-4:after {
    @include particles(700);
    height: 1px;
    width: 1px;
}

@keyframes animParticle {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY($spacing * -1);
    }
}

// .page-wrapper {
//   text-align: center;
//   color: #fff;
//   z-index: 2;
// }

// h4 {
//   color: #fff;
//   font-family: Monoton;
//   -webkit-animation: neon1 1.5s ease-in-out infinite alternate;
//   -moz-animation: neon1 1.5s ease-in-out infinite alternate;
//   animation: neon1 1.5s ease-in-out infinite alternate;
// }

// h4:hover {
//   color: #FF1177;
//   -webkit-animation: none;
//   -moz-animation: none;
//   animation: none;
// }

// /*glow for webkit*/

// @-webkit-keyframes neon1 {
//   from {
//     text-shadow: 0 0 10px #fff,
//       0 0 20px #fff,
//       0 0 30px #fff,
//       0 0 40px #FF1177,
//       0 0 70px #FF1177,
//       0 0 80px #FF1177,
//       0 0 100px #FF1177,
//       0 0 150px #FF1177;
//   }
//   to {
//     text-shadow: 0 0 5px #fff,
//       0 0 10px #fff,
//       0 0 15px #fff,
//       0 0 20px #FF1177,
//       0 0 35px #FF1177,
//       0 0 40px #FF1177,
//       0 0 50px #FF1177,
//       0 0 75px #FF1177;
//   }
// }

// /*glow for mozilla*/

// @-moz-keyframes neon1 {
//   from {
//     text-shadow: 0 0 10px #fff,
//       0 0 20px #fff,
//       0 0 30px #fff,
//       0 0 40px #FF1177,
//       0 0 70px #FF1177,
//       0 0 80px #FF1177,
//       0 0 100px #FF1177,
//       0 0 150px #FF1177;
//   }
//   to {
//     text-shadow: 0 0 5px #fff,
//       0 0 10px #fff,
//       0 0 15px #fff,
//       0 0 20px #FF1177,
//       0 0 35px #FF1177,
//       0 0 40px #FF1177,
//       0 0 50px #FF1177,
//       0 0 75px #FF1177;
//   }
// }

// /*glow*/

// @keyframes neon1 {
//   from {
//     text-shadow: 0 0 10px #fff,
//       0 0 20px #fff,
//       0 0 30px #fff,
//       0 0 40px #FF1177,
//       0 0 70px #FF1177,
//       0 0 80px #FF1177,
//       0 0 100px #FF1177,
//       0 0 150px #FF1177;
//   }
//   to {
//     text-shadow: 0 0 5px #fff,
//       0 0 10px #fff,
//       0 0 15px #fff,
//       0 0 20px #FF1177,
//       0 0 35px #FF1177,
//       0 0 40px #FF1177,
//       0 0 50px #FF1177,
//       0 0 75px #FF1177;
//   }
// }
