@import url('https://fonts.googleapis.com/css2?family=Ramabhadra&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ropa+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


/* ----------------INÍCIO BANNER----------------- */

.banner{
    height: 100vh;
    width: 100%;
    background-image: url(../assets/bannerSiteFundo.jpg);
    z-index: -2;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    position: relative;
    justify-content: space-evenly;
    /* align-items: flex-end; */
    /* position: relative; */
}

.barras{
    height: 200px;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content:flex-end;
}

.barras img{
    width: 150px;
    height: 150px;
}

.meninasConteudo{
    width: 1100px;
    height: 969px;
    background-image: url(../assets/meninas2.png);
    background-repeat: no-repeat;
    background-size: 85% 80%;
    background-position: bottom;
    position: relative;
}

.meninasConteudo img{
    width: 95%;
    position: absolute;
    top: 20px;
    left: 20px;
}

.textoBannerAnterior{
    height: 100%;
    display: flex;
    align-items:flex-end;
}

.textoBanner{
    height: 60%;
    width: 600px;
    display: flex;
    justify-content: space-evenly;
    /* background-color: #fff; */
    font-size: 250%;
    font-family: 'Poppins', sans-serif;
    color: #85ffa5;
    flex-direction: column;
    align-items: center;
    /* text-transform: uppercase; */
}

.textoBanner a{
    text-decoration: none;
    color: #fff;
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: 40px;
    text-transform: none;
    cursor: pointer;
    
}

.link{
    cursor: pointer;
   
}

.flechinhas{
    position: absolute;
    width: 50%;
    display: flex;
    justify-content: center;
    bottom: 0;
}



/* ----------------FIM BANNER---------------------- */



/* ----------------INÍCIO SOBRE-------------------- */

.sobreProjetoBackground{
    width: 100%;
    height: 800px;
    background-image: url(../assets/fundoSobre2.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}



.imagemSobre img{
    height: 750px;
}

.textoSobre img{
    height: 250px;
}

.textoSobre{
    height: 630px;
    /* background-color: #fff; */
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
   align-items:flex-end;
}

.textoPrincipal{
    width: 450px;
    text-align: right;
    height: 200px;
    color: #7b2be1;
    font-size: 22px;
    line-height: 30pt;
    margin-right: 30px;
    margin-top: 60px;
}


/* -----------------FIM SOBRE---------------------- */


/* header{
    background-color: #f9f9f9;
}
#check{
    display: none;
}

#icone{
    cursor: pointer;
    padding: 15px;
    position: absolute;
    z-index: 1;
    
}

.barra{
    background-color: rebeccapurple;
    height: 100%;
    width: 300px;
    position: absolute;
    right: 0;
    transition: all .2s linear;
}

#icone img{
    height: 50px;
    width: 50px;
} */



/* @media (max-width: 1000px){
    .banner{
        
    }
} */










